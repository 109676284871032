class WelcomeHero {
    constructor() {
        this.hero = document.getElementById('slideshow');
        this.timeout = false;
        this.scrolling = false;
        this.progressing = false;
        this.header = document.getElementById('main-site-header');
        this.slideLength = 7500;
    }

    pageHasWelcome() {
        return this.hero != null;
    }

    init() {
        this.scrollDown();
        this.fixFullHeight();
        this.setupSlideShow();
        //this.setupProgress();
    }

    fixFullHeight() {
        this.hero.classList.add('fas');
        this.hero.classList.add('fa-long-arrow-alt-down');
        if (!this.hero.classList.contains('slideshow--short')) {

            let height = window.innerHeight;

            this.hero.style.height = (height * .85) + 'px';

            this.header.classList.remove('main-site-header');
            this.header.classList.add('main-site-header--transparent');
            window.addEventListener('scroll', () => {
                window.clearTimeout(this.scrolling);
                this.scrolling = setTimeout(() => {
                    if (!this.isVisible()) {
                        this.header.classList.remove('main-site-header--transparent');
                        this.header.classList.add('main-site-header');
                    } else {
                        this.header.classList.add('main-site-header--transparent');
                        this.header.classList.remove('main-site-header');
                    }
                }, 5);

            });
        } else {
            this.hero.style.marginTop = this.header.clientHeight + 'px';
        }
    }

    countSlides() {
        this.slideCount = this.hero.querySelectorAll('.slideshow__slide').length;
    }

    setupSlideShow() {
        this.countSlides();
        if (this.slideCount < 2) {
            return;
        }
        this.direction = 'ltr';
        this.insertPagination();
        this.startSlideShow();
    }

    startSlideShow() {
        return;
        this.interval = setInterval(() => {
            //this.restartProgress();
            let currentSlide = this.hero.getAttribute('data-slide');
            let nextSlide;
            if (currentSlide == 1) {
                this.direction = 'ltr';
            }
            if (currentSlide == this.slideCount) {
                this.direction = 'rtl';
            }

            if (this.direction == 'ltr') {
                nextSlide = (+currentSlide + 1);
            } else {
                nextSlide = (+currentSlide - 1);
            }
            this.hero.setAttribute('data-slide', nextSlide);
        }, this.slideLength);
    }

    insertPagination() {
        const pagination = document.createElement('div');
        pagination.classList.add('slideshow__pagination');
        let i = 0;
        while (i < this.slideCount) {
            let div = document.createElement('div');
            div.setAttribute('data-slide', (i + 1));

            let span = document.createElement('span');
            div.appendChild(span);

            div.addEventListener('click', (e) => {

                let target = e.target;

                if (target.nodeName == 'SPAN') {
                    target = target.parentElement;
                }

                let slide = target.getAttribute('data-slide');
                this.goToSlide(slide);
            });
            pagination.appendChild(div);
            i++;
        }
        this.hero.appendChild(pagination);
    }

    goToSlide(slide) {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        this.hero.setAttribute('data-slide', slide);
        //this.restartProgress();
        this.timeout = setTimeout(() => {
            this.startSlideShow();
        }, this.slideLength);
    }

    isVisible() {
        const rect = this.hero.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

        // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
        const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= this.header.clientHeight);
        const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

        return (vertInView && horInView);
    }

    scrollDown() {
        this.hero.addEventListener('click', (e) => {
            if (e.target.id != 'slideshow') {
                return;
            }
            let ele = e.target;
            let offset = ele.nextElementSibling.offsetTop - document.getElementById('main-site-header').clientHeight;
            console.dir(offset);
            window.requestAnimationFrame(this.scrollDown.bind(this));
            window.scrollTo(0, offset);
        });
    }

    setupProgress() {
        this.progress = this.hero.querySelector('.slideshow__progress');
        this.restartProgress();
    }

    restartProgress() {
        clearInterval(this.progressing);
        this.progress.classList.remove('slow');
        this.progress.style.width = '0px';
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
        const increment = windowWidth / 5;
        this.progress.classList.add('slow');
        this.progressing = setInterval(() => {
            let width = parseInt(this.progress.style.width, 10);
            if (width < windowWidth) {
                this.progress.style.width = width + increment + 'px';
            }
        }, 850);

    }
}

const welcomeHero = new WelcomeHero();
if (welcomeHero.pageHasWelcome() !== false) {
    welcomeHero.init();
}