class MobileToggle {
    constructor() {
        this.button = document.getElementById('mobile-menu-toggle');
        this.button_class = 'main-site-header__mobile-toggle';

        this.menu = document.getElementById('mobile-menu')
        this.menu_class = 'mobile-navigation';

        this.logo = document.getElementById('header-logo')
        this.logo_class = 'main-site-header__logo';

        this.state = '--open';

        this.header = document.getElementById('main-site-header');
    }

    init() {
        this.button.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggle()
        });
    }

    toggle() {
        this.button.classList.toggle(this.button_class);
        this.button.classList.toggle(this.button_class + this.state);

        this.menu.classList.toggle(this.menu_class);
        this.menu.classList.toggle(this.menu_class + this.state);

        this.logo.classList.toggle(this.logo_class);
        this.logo.classList.toggle(this.logo_class + '--white');

        this.header.classList.toggle('main-site-header--mobile-menu-open');
    }
}

const mobileToggle = new MobileToggle();
mobileToggle.init();