class VideoJs {
    constructor() {
        this.videos = document.querySelectorAll('div[data-video]');
    }

    hasVideo() {
        return this.videos.length > 0 && this.videos != 'undefined';
    }

    render() {
        for (this.counter = 0; this.counter < this.videos.length; this.counter++) {
            this.played = false;
            this.video = this.videos[this.counter];
            this.html();
            this.videoEventListeners();
        }
    }

    html() {
        this.videoEle = document.createElement('video');
        this.sourceEle = document.createElement('source');
        this.videoAttributes();
        this.sourceAttributes();

        this.videoEle.appendChild(this.sourceEle);
        this.video.appendChild(this.videoEle);
        this.position();
    }

    videoAttributes() {

        this.videoEle.style.opacity = 0;
        if (this.video.hasAttribute('data-autoplay')) {
            this.videoEle.autoplay = true;
        }

        if (this.video.hasAttribute('data-loop')) {
            this.videoEle.loop = true;
        }

        if (this.video.hasAttribute('data-preload')) {
            this.videoEle.preload = 'metadata';
        }

        if (this.video.hasAttribute('data-playsinline')) {
            this.videoEle.setAttribute('playsinline', '');
            this.videoEle.setAttribute('webkit-playsinline', '');
        }

        if (this.video.hasAttribute('data-muted')) {
            this.videoEle.muted = true;
        }

        let poster = this.video.style.backgroundImage.slice(4, -1).replace(/"/g, "");
        this.videoEle.poster = poster;
    }

    touchSupported() {
        return 'ontouchstart' in window || navigator.maxTouchPoints
    }

    videoEventListeners() {

        this.videoEle.addEventListener('play', (e) => {
            this.videoEle.style.opacity = 1;
        });

        this.videoEle.addEventListener('loadedmetadata', (e) => {
            this.playVideo();
        });

        if (this.touchSupported()) {
            document.body.addEventListener('touchend', () => {
                this.playVideo();
            });
            document.addEventListener('DOMContentLoaded', () => {
                var e = new Event('touchend');
                this.videoEle.dispatchEvent(e);
                this.playVideo();
            });
        }

    }

    playVideo() {
        if (this.videoEle.paused || !this.played) {
            this.played = true;
            this.videoEle.currentTime = 2;
            this.videoEle.play();
        }
    }

    sourceAttributes() {
        this.sourceEle.setAttribute('src', this.video.getAttribute('data-video'));
    }

    position() {
        this.tvScreen = document.getElementsByClassName('tv screen')[this.counter];

        //landscape
        if (this.tvScreen.clientWidth > this.tvScreen.clientHeight) {
            this.videoEle.style.width = this.tvScreen.clientWidth + 'px';
        }

        //portrait
        if (this.tvScreen.clientWidth < this.tvScreen.clientHeight) {
            this.videoEle.style.height = this.tvScreen.clientHeight + 'px';
        }

        if (this.tvScreen.clientWidth > this.videoEle.clientWidth) {
            this.videoEle.style.marginLeft = (0 - ((this.tvScreen.clientWidth - this.videoEle.clientWidth) / 2)) + 'px';
        } else if (this.tvScreen.clientWidth < this.videoEle.clientWidth) {
            this.videoEle.style.marginLeft = (0 - ((this.videoEle.clientWidth - this.tvScreen.clientWidth) / 2)) + 'px';
        }
    }
}

const VideoJS = new VideoJs();
VideoJS.render();