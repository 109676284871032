import LazyLoad from "vanilla-lazyload";

const $lazyLoad = new LazyLoad({
    elements_selector: '.lazy'
});


const picture = document.getElementsByTagName('picture');

for (let i = 0; i < picture.length; i++) {
    let picture_ele = picture[i];
    let children = picture[i].children
    for (let j = 0; j < children.length; j++) {
        let child = children[j];
        if (child.nodeName == 'SOURCE') {
            child.setAttribute('srcset', child.getAttribute('data-src'));
            child.removeAttribute('src');
            child.removeAttribute('data-src');
        } else {
            child.setAttribute('src', child.getAttribute('data-src'));
            child.removeAttribute('data-src');
            child.onload = () => {
                fill_container(picture_ele);
            }
        }
    }
}

function is_landscape(ele) {
    const width = ele.offsetWidth || ele.innerWidth;
    const height = ele.offsetHeight || ele.innerHeight;

    if (width >= height) {
        if (!ele.hasOwnProperty('screen')) ele.setAttribute('is_landscape', true);
        return true;
    }
    if (!ele.hasOwnProperty('screen')) ele.setAttribute('is_landscape', false);
    return false;
}

function property(e, p) {
    return parseInt(window.getComputedStyle(e, null).getPropertyValue(p));
}

function fill_container(bg) {
    if (!bg.classList.contains('as_background')) return;

    const img = bg.lastElementChild;
    const container = bg.parentElement;

    const img_styles = window.getComputedStyle(img, null);
    const container_styles = window.getComputedStyle(container, null);

    const container_width = parseInt(container_styles.getPropertyValue('width'));
    const container_height = parseInt(container_styles.getPropertyValue('height'));

    let img_width = parseInt(img_styles.getPropertyValue('width'));
    let img_height = parseInt(img_styles.getPropertyValue('height'));

    const diff_x = (container_width - img_width);

    const diff_y = (container_height - img_height);

    if (diff_x >= diff_y) img.style.width = container_width + 'px';

    if (diff_y > diff_x) img.style.height = container_height + 'px';

    let offset_x = (img.offsetWidth - container.offsetWidth) / 2;
    let offset_y = (img.offsetHeight - container.offsetHeight) / 2;

    bg.style.left = parseInt(0 - offset_x) + 'px';
    bg.style.top = parseInt(0 - offset_y) + 'px';
}