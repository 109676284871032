import gliojs from 'gliojs';

class ExitIntent {
    constructor(type) {
        this.popup = document.querySelector('.exit-intent.' + type);
        this.closer = this.popup.querySelector('.exit-intent__close');
    }

    direction() {
        let direction = this.popup.getAttribute('data-direction');

        if (direction == '') return 'top';

        return direction;
    }

    init() {
        this.eventListeners();
        // glio.init([
        //     this.direction(),
        //     this.show()
        // ]);
        glio.init([
            this.direction(),
            () => {
                this.show();
            }
        ]);
    }

    eventListeners() {
        this.closer.addEventListener('click', () => {
            this.hide();
        });

        window.addEventListener('blur', () => {
            this.show();
        });
    }

    alreadySeen() {
        let cookie = this.popup.getAttribute('data-type') + '--exit-intent';
        let re = new RegExp(cookie, 'g');
        return document.cookie.match(re);
    }

    setCookie() {
        let d = new Date();
        d.setTime(d.getTime() + (10 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = this.popup.getAttribute('data-type') + '--exit-intent=true; ' + expires;

    }

    show() {
        if (this.alreadySeen()) return;
        window.loadJS('https://platform.linkedin.com/in.js', 1, true, false, true);
        this.popup.classList.add('exit-intent--open');
        this.popup.classList.remove('exit-intent');
        this.setCookie();
    }

    hide() {
        this.popup.classList.add('exit-intent');
        this.popup.classList.remove('exit-intent--open');
    }
}

const ExitIntents = [];
const popups = document.querySelectorAll('.exit-intent');
popups.forEach((ele) => {
    let ei = new ExitIntent(ele.getAttribute('data-type'));
    ei.init();
    ExitIntents.push(ei);
});