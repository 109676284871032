class tracking {
    constructor() {
        this.trackedElements = [];
        this.scrolling = false;
        this.scrollTimeout = 0;
        this.scrollDepth = 0;
        this.init();
    }
    init() {
        this.trackedElements = document.querySelectorAll('[data-track]');
        this.trackedElements.forEach((element, index) => {
            let action = element.getAttribute('data-action').toLowerCase();
            switch (action) {
                case 'click':
                    this.trackClick(element, index);
                    break;
            }
        });

        this.trackScrollDepth();
        return;
    }
    trackClick(element, index) {
        element.addEventListener('click', (e) => {
            e.preventDefault();

            const category = element.getAttribute('data-category');
            const action = element.getAttribute('data-action');
            const label = element.getAttribute('data-label') || '';

            this.sendEvent(category, action, label);

            if (this.elementIs(element, 'a')) {
                const href = element.getAttribute('href');
                if (href == '#') return;
                const target = element.getAttribute('target') || '_self';
                window.open(href, target);
            }
        });
    }

    trackScrollDepth() {
        document.addEventListener('scroll', () => {
            if (this.scrolling) return;

            this.scrolling = true;

            clearTimeout(this.scrollTimeout);

            this.scrollTimeout = setTimeout(() => {
                this.scrolling = false;
            }, 300)

            const h = document.documentElement;
            const b = document.body;
            const st = 'scrollTop';
            const sh = 'scrollHeight';

            let percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);

            //round down to nearest 10
            percent = percent - (percent % 10);

            if (this.scrollDepth === percent) return;

            this.scrollDepth = percent;

            this.sendEvent('Scroll to ' + percent + '%', 'Scroll', b.getAttribute('data-page'));
        });
    }

    elementIs(element, match) {
        return element.tagName.toLowerCase() == match;
    }

    sendEvent(category, action, label, props) {
        props = props || false;

        if (typeof galite === 'function') galite('send', 'event', category, action, label, props);
    }
}

const track = new tracking();
