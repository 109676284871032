class MobileMenuChildrenLinks {
    constructor() {
        this.mobileMenuContainer = document.querySelector('.mobile-navigation__menu');
        this.parentLinks = this.mobileMenuContainer.querySelectorAll('.has-children');
        this.toggleClass = 'show-children';
    }

    init() {
        this.parentLinks.forEach((element, index) => {
            this.insertToggle(element);
        });
    }

    insertToggle(element) {
        element.appendChild(this.createToggle());
    }

    createToggle() {
        let toggle = document.createElement('span');
        toggle.classList.add('toggle');
        toggle.innerHTML = '&Cross;';
        toggle.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggle(e.target.parentNode);
        })
        return toggle;
    }

    toggle(element) {
        element.classList.toggle(this.toggleClass);
    }
}

const mobileMenuChildrenLinks = new MobileMenuChildrenLinks();
mobileMenuChildrenLinks.init();