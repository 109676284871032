class Features {
    constructor() {
        this.features = document.getElementsByClassName('features');
        this.feature = false;
        this.list = false;
        this.images = false;
    }

    exists() {
        return this.features != 'undefined' && this.features != undefined && this.features.length > 0;
    }

    init() {
        for (let feature of this.features) {
            this.feature = feature;
            this.eventListener();
            this.equalHeight();
            this.activateFirst();
        }
    }

    activateFirst() {
        let item = this.list.getElementsByTagName('li')[0];
        item.click();
    }

    eventListener() {
        this.list = this.feature.querySelector('.features__list');
        this.images = this.feature.querySelector('.features__images');
        for (let item of this.list.children) {
            item.addEventListener('click', () => {
                let data_feature = item.getAttribute('data-feature');
                this.toggle(data_feature);
            });
        }
    }

    toggle(data_feature) {
        let item = document.querySelector('li[data-feature="' + data_feature + '"]');
        let image = document.querySelector('div[data-feature="' + data_feature + '"');

        let parent_list = item.parentNode; //item.closest('.features__list');

        let images = parent_list.previousElementSibling;

        for (let child_item of parent_list.children) {
            child_item.classList.add('features__item');
            child_item.classList.remove('features__item--active');
        }
        //console.dir(images);
        for (let child_image of images.children) {
            child_image.classList.remove('features__image--active');
            child_image.classList.add('features__image');
        }

        if (!item.classList.contains('features__item--active')) {
            item.classList.add('features__item--active');
            item.classList.remove('features__item');

            image.classList.add('features__image--active');
            image.classList.remove('features__image');
        } else {
            item.classList.add('features__item');
            item.classList.remove('features__item--active');

            image.classList.add('features__image');
            image.classList.remove('features__image--active');
        }
    }

    equalHeight() {
        let list_styles = window.getComputedStyle(this.list, null);

        let list_height = parseInt(
            list_styles.getPropertyValue('height')
        );

        const width = window.innerWidth;
        const height = window.innerHeight;

        if (width < height) list_height = list_height / 3;

        this.images.style.height = list_height + 'px';
    }


}

const features = new Features();
if (features.exists()) {
    features.init();
}