import 'cookieconsent';
import css from 'cookieconsent/build/cookieconsent.min.css';

window.cookieconsent.initialise({
    "type": 'info',
    "position": 'bottom',
    "palette": {
        "popup": {
            "background": "#006a8b"
        },
        "button": {
            "background": "transparent",
            "border": "#fff",
            "text": "#fff",
            padding: '5px 40px'
        }
    },
    "content": {
        "href": "/cookies"
    }
});