class ActionButtons {
    constructor() {
        this.menu = document.getElementById('action-buttons');//.querySelectorAll('.action-buttons__item');
        this.buttons = this.menu.querySelectorAll('.action-buttons__link');
        this.toggle = this.buttons[0];
        this.scroll_pos = 0;
    }

    init() {
        this.registerEventListeners();
    }

    registerEventListeners() {
        this.buttons.forEach((element, index) => {
            let button = element;

            let action = button.getAttribute('data-action');

            let is_link = button.classList.length <= 1;

            if (button.classList.contains('toggle')) {
                button.addEventListener('click', (e) => {
                    e.preventDefault();

                    if (this.menu.classList.contains('action-buttons')) {
                        this.menu.classList.add('action-buttons--open');
                        this.menu.classList.remove('action-buttons');
                        //galite('send', 'event', 'Menu', 'Open', 'Action Buttons');
                    } else {
                        this.menu.classList.add('action-buttons');
                        this.menu.classList.remove('action-buttons--open');
                        //galite('send', 'event', 'Menu', 'Close', 'Action Buttons');
                    }
                });
            }

            if (button.classList.contains('newsletter')) {
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    document.getElementById('subscribe').querySelector('.subscribe__open').click();
                });
            }

            if (is_link) {
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    //do tracking logic
                    //galite('send', 'event', action, 'Click', 'Action Button');
                    this.toggle.click();
                    //window.open(button.href, button.target);
                });
            }

        });

        window.addEventListener('scroll', () => {
            let top = (document.body.getBoundingClientRect()).top;
            if (top < this.scrollPos) {
                if (!this.menu.classList.contains('action-buttons--open')) {
                    this.menu.classList.add('nudge');
                }
            } else {
                this.menu.classList.remove('nudge');
            }
            this.scrollPos = (document.body.getBoundingClientRect()).top;
        });

    }
}

const actionButtons = new ActionButtons();
actionButtons.init();
