//import '@babel/polyfill';
import fslightbox from 'fslightbox/fslightbox';
import css from 'fslightbox/fslightbox.min.css';


(function () {
    window.loadStyle(theme_url + '/assets/css/vendor.css?v=' + theme_version, 0, false, false);
    window.loadStyle(theme_url + '/assets/css/btf.css?v=' + theme_version, 1, function () {
        css_loaded = true;
    }, false);
})();