class Masonary {
    constructor() {
        this.masonarys = document.getElementsByClassName('archive--multiple');
        this.masonary = false;
        this.items = false;
        this.rowGap = 20;
        this.rowHeight = 10;
    }

    exists() {
        return this.masonarys != 'undefined' && this.masonarys != undefined && this.masonarys.length > 0;
    }

    init() {
        this.setupMasonaryGrids();
    }

    setupMasonaryGrids() {
        for (this.masonary of this.masonarys) {
            this.rowGap = parseInt(window.getComputedStyle(this.masonary.children[0]).getPropertyValue('grid-row-gap'), 10);
            this.rowHeight = parseInt(window.getComputedStyle(this.masonary.children[0]).getPropertyValue('grid-auto-rows'), 10);
            this.resizeItems();
        }
    }

    resizeItems() {
        this.items = this.masonary.getElementsByTagName('article');
        for (let item of this.items) {
            let anchor = item.children[0];
            let rowSpan = Math.ceil((anchor.getBoundingClientRect().height + this.rowGap) / (this.rowHeight + this.rowGap));
            if (item.classList.contains('archive__single--image')) {
                rowSpan--;
            }
            item.style.gridRowEnd = 'span ' + rowSpan;
        }
    }

}

var m_int = false;

m_int = setInterval(() => {
    if (css_loaded) {
        const masonary = new Masonary();
        if (masonary.exists()) {
            masonary.init();
        }
        clearInterval(m_int);
    }
}, 500);
