import $ from 'jquery';
class Subscribe {
    constructor() {
        this.subscribe = document.getElementById('subscribe');
        this.opener = this.subscribe.querySelector('.subscribe__open');
        this.closer = this.subscribe.querySelector('.subscribe__close');
        this.form = this.subscribe.querySelector('form');
        this.button = this.subscribe.querySelector('.button-primary');
        this.scroll_pos = 0;
    }

    init() {
        this.eventListeners();
    }

    eventListeners() {
        this.opener.addEventListener('click', () => {
            this.subscribe.classList.add('subscribe');
            this.subscribe.classList.remove('subscribe--closed');
            this.subscribe.classList.remove('nudge');
            galite('send', 'event', 'Newsletter', 'Toggle', 'Open');
        });

        this.closer.addEventListener('click', () => {
            this.subscribe.classList.add('subscribe--closed');
            this.subscribe.classList.remove('subscribe');
            //galite('send', 'event', 'Newsletter', 'Toggle', 'Close');
        });

        window.addEventListener('scroll', () => {
            let top = (document.body.getBoundingClientRect()).top;
            if (top < this.scrollPos) {
                if (this.subscribe.classList.contains('subscribe--closed')) {
                    this.subscribe.classList.add('nudge');
                }
            } else {
                this.subscribe.classList.remove('nudge');
            }
            this.scrollPos = (document.body.getBoundingClientRect()).top;
        });

        this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            this.button.value = 'Please Wait...';
            this.addSubscriber();
        });
    }

    addSubscriber() {
        let inputs = this.form.querySelectorAll('input');
        let data = {};
        let formData = new FormData();
        inputs.forEach((value, index) => {
            let input = inputs[index];
            if (input.type == 'submit') return;

            data[input.name] = input.value;
            formData.append(input.name, input.value);

        });
        let queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');


        $.ajax({
            url: this.form.getAttribute('action'), // + '?' + queryString,
            type: this.form.getAttribute('method'),
            data: data,
            dataType: 'jsonp',
            cache: false,
            jsonp: 'c',
            contentType: "application/json; charset=utf-8",
            success: (data) => {
                //console.dir(data);
                let response = data; // JSON.parse(data);
                this.toast(response.msg, response.result);
                this.button.value = 'Subscribe';
                galite('send', 'event', 'Newsletter', 'Signup', 'Success');
                this.closer.click();
            },
            error: (data) => {
                let response = data; //JSON.parse(data);
                this.toast(response.msg, response.result);
                this.button.value = 'Subscribe';
                galite('send', 'event', 'Newsletter', 'Signup', 'Error');
            }
        });


        // xhr.open(this.form.getAttribute('method'), this.form.getAttribute('action') + '?' + queryString);
        // xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
        // xhr.responseType = 'json';
        // xhr.onreadystatechange = () => {
        //     if (xhr.readyState === 4) {

        //         switch (xhr.status) {
        //             case 200:
        //                 this.toast(xhr.responseText, 'success');
        //                 break;

        //             case 0:

        //                 break;

        //             default:
        //                 this.toast(xhr.responseText, 'error');
        //                 break;
        //         }
        //     }
        // }
        // xhr.send(data);
    }

    toast(message, status) {
        let toast = document.createElement('div');

        toast.classList.add('alert--' + status)
        toast.classList.add('toast');
        toast.innerHTML = message;

        document.body.appendChild(toast);
        setTimeout(() => {
            toast.style.opacity = 0;

            setTimeout(() => {
                toast.remove();
            }, 500);

        }, 5000);
    }
}

const $subscribe = new Subscribe();
$subscribe.init();
